import React from 'react'
import tw from 'twin.macro'
import StandingsBig from '@elements/StandingsBig'
import Standings from '@elements/Standings'
import { useBreakpoint } from '@hooks/useBreakpoint'
import Stripes from '@elements/Stripes'
import useGoogleSheets from 'use-google-sheets'

const Section = tw.section`relative mb-[320px] xs:mb-[380px] md:mb-[480px] 2xl:mb-[650px]`

const WpAcfTeamstandingsBlock = ({ locale }) => {
	const { isMobile } = useBreakpoint()
	const lang = locale.substr(0, 2)
	const [gsheet, setGsheet] = React.useState(null)
	const { data, loading, error } = useGoogleSheets({
		apiKey: 'AIzaSyDqHcXpu3mJ5-KL5LA4Y3eCBDL7xorPRB8',
		sheetId: '1laFhSZRaQlElpcwnMVbUJrs0H-kLlXCusu0Whwxm50o',
		sheetsNames: ['Sheet1', 'Sheet2'],
	})

	React.useEffect(() => {
		data.length !== 0 && setGsheet(data)
	}, [data])

	return (
		<Section>
			{isMobile && gsheet && gsheet.length > 0 && <Standings lang={lang} sheetData={gsheet} loading={!loading} />}
			{!isMobile && gsheet && gsheet.length > 0 && <StandingsBig lang={lang} sheetData={gsheet} />}
			<Stripes className='top-full rot-x-180' />
		</Section>
	)
}
export default WpAcfTeamstandingsBlock
